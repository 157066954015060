import DataLoader from 'dataloader';
import {
  BookAndNetworkIntersectionDocument,
  BookAndNetworkIntersectionQuery,
  BookAndNetworkIntersectionQueryVariables,
  BookAndProfilesPartsFragment,
  Maybe,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';
import { emitter, Payload } from './events';

const bookNetworkLoader = new DataLoader(
  async (keys: readonly string[]): Promise<Maybe<BookAndProfilesPartsFragment | null>[]> => {
    const client = getApolloClient();

    const { data } = await client.query<
      BookAndNetworkIntersectionQuery,
      BookAndNetworkIntersectionQueryVariables
    >({
      query: BookAndNetworkIntersectionDocument,
      variables: { bookIds: [...keys] },
      fetchPolicy: 'no-cache',
    });
    return data.bookAndNetworkIntersection || [];
  },
  { batch: true, cache: true, cacheKeyFn: (key: string): string => `bookNetwork:${key}` }
);

type Props = {
  bookId?: string | null;
  skip?: boolean;
};

export function useBookNetworkLoader({ bookId, skip = false }: Props) {
  const { isSignedIn } = useAuthContext();
  const { recEngineLive } = useSiteConfig();
  const [data, setData] = useState<BookAndProfilesPartsFragment | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (skip || !recEngineLive || !isSignedIn || !bookId) return;
    load();
    function applyNewData({ key, value }: Payload<BookAndProfilesPartsFragment | null>) {
      if (key === bookId) setData(value);
    }
    emitter.on('bookNetwork', applyNewData);
    return () => emitter.off('bookNetwork', applyNewData);
  }, [bookId, skip, recEngineLive, isSignedIn]);

  async function load() {
    if (skip || !recEngineLive || !isSignedIn || !bookId) return;
    setLoading(true);
    const newVal = await bookNetworkLoader.load(bookId);
    emitter.emit('bookNetwork', { key: bookId, value: newVal });
    setLoading(false);
  }

  async function refetch() {
    if (!bookId) return;
    bookNetworkLoader.clear(bookId);
    await load();
  }

  return { data, setData, refetch, loading };
}
