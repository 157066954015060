import classNames from 'classnames';
import UserGroup from 'components/basics/UserGroup';
import {
  BookAndProfilesPartsFragment,
  BookPartsFragment,
  ProfilePartsFragment,
  ReadingStateWorkPartsFragment,
  ReviewPartsFragment,
} from 'generated/graphql';
import { useLongPress } from 'hooks/useLongPress';
import Link from 'next/link';
import { ReactNode, useMemo } from 'react';
import { Cover, Tag } from 'ui/generic';
import { LibraryIcon } from 'ui/icons';
import { noop } from 'utils/noop';
import InlineBookStatus from './InlineBookStatus';
import styles from './Item.module.scss';

interface InnerProps {
  book: BookPartsFragment;
  momentsCount?: number;
  readingStates?: ReadingStateWorkPartsFragment[];
  controls?: ReactNode;
  profile?: ProfilePartsFragment | null;
  hideAvatar?: boolean;
  subtitle?: string | ReactNode;
  review?: ReviewPartsFragment | null;
  bookNetwork?: BookAndProfilesPartsFragment | null;
}

const Inner = ({
  book,
  momentsCount,
  readingStates,
  controls,
  hideAvatar = false,
  subtitle,
  review,
  bookNetwork,
}: InnerProps): JSX.Element => {
  const isShowingAvatar = useMemo(
    () => readingStates && readingStates?.some((x) => x.bookId == book.id),
    [readingStates, book]
  );

  const extraUserCount = useMemo(() => {
    if (!bookNetwork) return null;
    const count = bookNetwork.count - 2;
    return count > 0 ? count : null;
  }, [bookNetwork]);

  return (
    <div className={styles.box}>
      <div className={styles.inner}>
        {!hideAvatar && (
          <div className={styles.yourStatus}>
            {bookNetwork && (
              <UserGroup
                avatarSize={34}
                extraUserCount={extraUserCount}
                users={bookNetwork.profiles.slice(0, 2)}
              />
            )}
          </div>
        )}
        <div className={styles.stickers}>
          {isShowingAvatar && !hideAvatar && <Tag noHover icon={<LibraryIcon />}></Tag>}
          <InlineBookStatus momentsCount={momentsCount} rating={review?.rating} />
        </div>

        <div className={styles.image}>
          <Cover book={book} />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>{book.title}</div>
          {book.authors && <div className={styles.authors}>{book.authors.map((a) => a.name).join(', ')}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      </div>
      {controls && (
        <div className={styles.controls} role="book-item-controls">
          {controls}
        </div>
      )}
    </div>
  );
};

export type BookItemProps = InnerProps & {
  onClick?: () => void;
  trackClick?: () => void;
  href?: string;
  as?: string;
  controls?: ReactNode;
  isOptionsMenuOpen?: boolean;
  hideAvatar?: boolean;
  subtitle?: string | ReactNode;
  review?: ReviewPartsFragment | null;
  onLongPress?: () => void;
  bookNetwork?: BookAndProfilesPartsFragment | null;
};

const BookListItem = ({
  book,
  onClick,
  trackClick,
  href,
  as,
  controls,
  readingStates,
  momentsCount,
  isOptionsMenuOpen,
  hideAvatar,
  subtitle,
  review,
  bookNetwork,
  onLongPress = noop,
}: BookItemProps): JSX.Element => {
  const longPress = useLongPress(onLongPress);

  if (onClick) {
    return (
      <div className={classNames({ isOptionsMenuOpen }, styles.container)} role="book-item" {...longPress()}>
        <button
          className={styles.outerButton}
          onClick={() => {
            onClick();
            if (trackClick) trackClick();
          }}
        >
          <Inner
            bookNetwork={bookNetwork}
            book={book}
            readingStates={readingStates}
            controls={controls}
            hideAvatar={hideAvatar}
            subtitle={subtitle}
            review={review}
          />
        </button>
      </div>
    );
  }
  return (
    <div
      {...longPress()}
      onClick={trackClick ? trackClick : () => {}}
      className={classNames({ [styles.isOptionsMenuOpen]: isOptionsMenuOpen }, styles.container)}
    >
      <Link href={href || '/book/[bookSlug]'} as={as || `/book/${book.slug}`}>
        <a draggable="false">
          <Inner
            bookNetwork={bookNetwork}
            book={book}
            readingStates={readingStates}
            momentsCount={momentsCount}
            controls={controls}
            hideAvatar={hideAvatar}
            subtitle={subtitle}
            review={review}
          />
        </a>
      </Link>
    </div>
  );
};

export default BookListItem;
