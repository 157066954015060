import BookItem, { BookItemProps } from 'components/book/Item';
import BookItemOptions from 'containers/BookItemOptions/BookItemOptions';
import { useBookNetworkLoader } from 'dataloaders/bookNetworkLoader';
import { useMomentCountLoader } from 'dataloaders/momentCountLoader';
import { useReviewLoader } from 'dataloaders/reviewLoader';
import { useAuthContext } from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import { useMyReadingStates } from 'hooks/useReadingStates';
import { useState } from 'react';
import { ImpressionSource } from 'types/analytics';
import { ActionItemType } from 'ui/specific/actionList';

type Props = Omit<BookItemProps, 'controls' | 'readingStates' | 'momentsCount'> & {
  profileId?: string;
  additionalOptions?: ActionItemType[];
  optionsPositioningStrategy?: 'fixed' | 'absolute';
  hideBookOptions?: boolean;

  // Controls the display of the network avatars on the book item
  hideAvatar?: boolean;

  // Used to track where the book is ranked (e.g. in the search results)
  positionInList?: number;
  trackImpressions?: boolean;
  impressionSource?: ImpressionSource;
};

/**
 * todo: refactor this component next time its touched
 */
const BookItemsContainer = ({
  profileId,
  additionalOptions,
  optionsPositioningStrategy = 'absolute',
  hideBookOptions = false,
  hideAvatar = false,
  trackImpressions = false,
  positionInList,
  impressionSource,
  ...props
}: Props): JSX.Element => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const { readingStates } = useMyReadingStates();
  const { profile, isSignedIn } = useAuthContext();
  const { data: momentsCount } = useMomentCountLoader({ bookId: props.book.id, profileId });
  const { data: review } = useReviewLoader({ profileId, bookId: props.book.id });
  const { data: bookNetwork } = useBookNetworkLoader({
    bookId: props.book.id,
    skip: hideAvatar || !isSignedIn,
  });
  const mixpanel = useMixpanel();

  function onBookImpression(type: 'open_menu' | 'click') {
    if (!trackImpressions) return;
    mixpanel.trackEvent('search_book_impression', {
      bookId: props.book.id,
      title: props.book.title,
      profileId,
      impressionType: type,
      positionInList,
      impressionSource,
    });
  }

  return (
    <BookItem
      {...props}
      onLongPress={() => setOpenDropdown(true)}
      trackClick={() => onBookImpression('click')}
      momentsCount={momentsCount}
      readingStates={readingStates || undefined}
      profile={profile}
      isOptionsMenuOpen={isOpenDropdown}
      hideAvatar={hideAvatar}
      bookNetwork={bookNetwork}
      review={review}
      controls={
        !hideBookOptions ? (
          <BookItemOptions
            book={props.book}
            additionalOptions={additionalOptions}
            positioningStrategy={optionsPositioningStrategy}
            isOpen={isOpenDropdown}
            setOpen={setOpenDropdown}
          />
        ) : null
      }
    />
  );
};

export default BookItemsContainer;
